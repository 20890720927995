import { ref } from 'vue'
import { auth } from '../../firebase/config'
import getUser from "@/composables/getUser";


const userVerified = ref(null)
const userHasAccess = ref(null)
const backdoor = [
  "violegacy2020@gmail.com",
  "linyuxuan0122@gmail.com",
  "victorjsun@gmail.com",
  "boranwang1995@gmail.com",
  "yentingw.gin@gmail.com"
]

auth.onAuthStateChanged(_user => {
  if(_user){
    const { user } = getUser();
    // console.log("Verification Status is currently " + _user.emailVerified)
    userVerified.value = _user.emailVerified
    // console.log("Is NYU Email: " + user.value.email.endsWith("@nyu.edu"))
    userHasAccess.value = userVerified.value && user.value.email.endsWith("@nyu.edu")
    if(backdoor.includes(user.value.email)){
      userHasAccess.value = true
    }
  }
});
  
const getUserAccessStatus = () => {
  return { userVerified, userHasAccess }
}

export default getUserAccessStatus