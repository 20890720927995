import localStorage from 'localStorage'
import firebase from 'firebase/app'

export function isEqual(obj1, obj2) {
  if (typeof obj1 === "object") {
    if (typeof obj2 !== "object") return false;
    if (obj1 === null) return obj2 === null;
    if (Array.isArray(obj1)) {
      if (!Array.isArray(obj1)) return false;
      let result = true;
      obj1.forEach((element, index) => {result = result && isEqual(element, obj2[index])});
      return result;
    }
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)
    if (keys1.length !== keys2.length) return false;
    let result = true;
    keys1.forEach(key => {
      result = result && isEqual(obj1[key], obj2[key]);
    })
    return result;
  }
  return obj1 === obj2;
}

function formatObj(obj) {
  if (obj == null) return null
  if (Array.isArray(obj)) {
    return obj.map(element => formatObj(element))
  }
  if (typeof obj == "object") {
    const keys = Object.keys(obj)
    for (let i = 0; i < keys.length; ++i) {
      const key = keys[i]
      if (obj[key] != null) {
        if (obj[key]["seconds"] != undefined && obj[key]["nanoseconds"] != undefined) {
          obj[key] = new firebase.firestore.Timestamp(obj[key]["seconds"], obj[key]["nanoseconds"])
        }
        if (typeof obj[key] == "object") {
          obj[key] = formatObj(obj[key])
        }
      }
    }
  }
  return obj
}

function getFromCache(key) {
  let obj = localStorage.getItem(key);
  if (obj == null) return null;
  return formatObj(JSON.parse(obj))
}

function addToCache(key, value) {
  let cachedItems = localStorage.getItem("cachedItems")
  if (cachedItems == null) {
    cachedItems = {}
  } else {
    cachedItems = JSON.parse(cachedItems)
  }
  cachedItems[key] = true;
  localStorage.setItem(key, value)
  localStorage.setItem("cachedItems", JSON.stringify(cachedItems))
}

const wrapWithCache = (fn, cacheName) => {
  async function cachedFn(store, ...args) {
    const detailedCacheName = cacheName + "-" + JSON.stringify(args)
    var obj = getFromCache(detailedCacheName);
    if (obj != null && process.env.NODE_ENV !== 'test') {
      store.value = obj;
      fn(store, ...args).then(() => {
        addToCache(detailedCacheName, JSON.stringify(store.value))
      })
    } else {
      await fn(store, ...args).then(() => addToCache(detailedCacheName, JSON.stringify(store.value)))
    }
  }
  return cachedFn
}

export function clearCache() {
  let cachedItems = localStorage.getItem("cachedItems");
  if (cachedItems == null) return;
  Object.keys(JSON.parse(cachedItems)).forEach(key => localStorage.removeItem(key));
  localStorage.removeItem("cachedItems")
}

export default wrapWithCache;
