import { db } from '@/firebase/config'
import wrapWithCache from '@/composables/wrapWithCache'

export const getProfile = async (store, uid) => {
  try {
    await db.collection("Profiles").doc(uid).get().then(doc => {
      store.value = { ...doc.data(), id: doc.id }
    })
  } catch (error) {
    alert("getProfile " + error)
  }

};

export default wrapWithCache(getProfile, "profile");
