<template>
  <router-view />
</template>

<style>

* {
  font-family: "Open Sans";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-wrap: break-word;
}

.wrap {
  white-space: pre-wrap;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.good-line {
  color: rgb(160, 160, 160);
  margin: 2vh 0;
}

.round-image {
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  object-fit: cover;
}

.big-icon {
  font-size: 250%;
  margin-right: 5%;
  margin-left: 10%;
}

.small-icon {
  font-size: 200%;
  margin-right: 5%;
  margin-left: 10%;
}

input:focus,
textarea:focus {
  padding: 0 1vw;
  outline: none;
}

input,
textarea {
  padding: 0 1vw;
  outline: none;
}

button {
  outline: none;
}

button:hover {
  outline: none;
  transition: all 0.25s ease;
}

.container {
  height: 100%;
}

.form {
  margin: 0 auto;
  width: 60vw;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .form {
    margin: 0;
    width: 100vw;
  }

  .signUp-container {
    margin: 4vh 0;
  }
}

.form input {
  display: block;
  width: 50%;
  font-size: 120%;
  margin: 1vh auto;
  margin-bottom: 2vh;
  outline: none;
  border: 2px var(--purple-l) solid;
  border-radius: 20px;
  height: 35px;
}

.form h4 {
  display: block;
  width: 50%;
  margin: 1vh auto;
}

.form textarea {
  padding: 1vh 1vw;
  font-size: 120%;
  display: block;
  width: 50%;
  margin: 1vh auto;
  margin-bottom: 2vh;
  outline: none;
  border: 2px var(--purple-l) solid;
  border-radius: 20px;
  height: 100px;
}

.form textarea::-webkit-scrollbar {
  display: none;
}

.back-top {
  color: grey;
  display: flex;
  align-items: center;
  width: 150px;
  margin-bottom: 2vh;
  transition: all 0.25s ease;
}

.back-top:hover {
  color: black;
  cursor: pointer;
}

.back-top div {
  font-size: 90%;
  display: flex;
  flex: 0 1 60px;
  align-items: center;
}

.back-top h2 {
  flex: 0 0 auto;
}

.warning {
  font-size: 75%;
  color: red;
}

.violet {
  color: var(--purple-l);
}

.red {
  color: red;
}

.green {
  color: green;
}

.grey {
  color: grey;
}
</style>
