import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { auth } from './firebase/config'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

let app

auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App).use(router)
    app.use(VueSweetalert2);
    //app.config.isCustomElement = tag => tag.startsWith('ion-icon')
    app.mount('#app')
    // console.log(app.config)
  }
})

