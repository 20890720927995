import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'
const prodFirebaseConfig = {
    apiKey: "AIzaSyBiCwymuT8cMZHBqG2csQ7OnDHsyyhrhl4",
    authDomain: "violegacy-26a40.firebaseapp.com",
    databaseURL: "https://violegacy-26a40.firebaseio.com",
    projectId: "violegacy-26a40",
    storageBucket: "violegacy-26a40.appspot.com",
    messagingSenderId: "49331994721",
    appId: "1:49331994721:web:ab5255883b3f9b077a2a20",
    measurementId: "G-R9G1V3E3RE"
};

const testFirebaseConfig = {
    apiKey: "AIzaSyAesJfN0eBWcZf5C85uVKGyksUxPbHLA7c",
    authDomain: "violegacy-test.firebaseapp.com",
    projectId: "violegacy-test",
    storageBucket: "violegacy-test.appspot.com",
    messagingSenderId: "547717208100",
    appId: "1:547717208100:web:2f90f9fe68d9db194b20b1",
    measurementId: "G-Y6HMMHW1E0"
  };

const config = process.env.NODE_ENV === 'production' ? prodFirebaseConfig : testFirebaseConfig;
if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') console.log("Using test project")

firebase.initializeApp(config)

const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()

const timestamp = firebase.firestore.FieldValue.serverTimestamp

var eventListeners = []

class MockAnalytics {
  constructor() {}
  logEvent(...args) {}
}

const analytics = process.env.NODE_ENV === 'test' ? new MockAnalytics() : firebase.analytics();
analytics.logEvent('notification_received');

export {db,auth,storage,timestamp,eventListeners,analytics}